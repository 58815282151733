export const CATALOG_PAGE_TYPES = {
  BRAND: 'brand',
  CATEGORY_PAGE: 'category',
  FAVORITE: 'favorite',
  OFFER_PAGE: 'offer',
  REDIRECT: 'redirect',
  SALE: 'sale',
  SEARCH: 'search',
  SEO_CATEGORY_PAGE: 'seo-category',
};
